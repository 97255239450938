import {Suspense, lazy, useEffect, useState} from 'react';
import { BrowserRouter, Routes, Route, Navigate, Outlet, useSearchParams, useLocation } from "react-router-dom";

import { Provider } from 'react-redux';
import store from './store';

import Config from './Config';
import { INITIAL_DATA } from './INITIAL_DATA';

import Util from './utils/Util';

const lazyDelay = 0;

const Home = lazy(async () => {await new Promise(resolve => setTimeout(resolve, lazyDelay));return import('./pages/Home/Home');});
const Menu = lazy(async () => {await new Promise(resolve => setTimeout(resolve, lazyDelay));return import('./pages/Menu/Menu');});
const Termeni = lazy(async () => {await new Promise(resolve => setTimeout(resolve, lazyDelay));return import('./pages/Termeni/Termeni');});
const Politica = lazy(async () => {await new Promise(resolve => setTimeout(resolve, lazyDelay));return import('./pages/Politica/Politica');});
const NotFound = lazy(async () => {await new Promise(resolve => setTimeout(resolve, lazyDelay));return import('./pages/NotFound/NotFound');});

export default function App() {

  	return (
		<Provider store={store}>

			<Start>

				<Suspense fallback={<Loader />}>

					<BrowserRouter basename='/'>
						<Routes>
							<Route path="/" element={<Root />} >

								<Route path="/" element={<Navigate to="/home" replace />} />
								<Route path="/home" element={<Home />} />
								<Route path="/menu" element={<Menu />} />
								<Route path="/menu/:productId" element={<Menu />} />

								<Route path="/termeni" element={<Termeni />} />
								<Route path="/politica" element={<Politica />} />

							</Route>

							<Route path="*" element={<NotFound />} />
						</Routes>
					</BrowserRouter>

				</Suspense>

			</Start>

		</Provider>
  	);
}

function Loader(props){
	return <div>loading...</div>
}

function Start(props){

    const [loading, setLoading] = useState(true);

    useEffect(()=>{

        let url = "/start.php";
        let options = {
            method: 'GET',
        }

        Util.request(url, options, 10000)
            .then((response) => response.json())
            .then((json) => {    

				for(let i = 0; i < json.products.length; i++){
					let price_full = json.products[i].price_full;
					let discount = json.products[i].discount;

					json.products[i].price = json.products[i].price_full;

					if(discount > 0){
						json.products[i].price = parseFloat((price_full - price_full * discount / 100).toFixed(2));
					}
				}

                INITIAL_DATA.open_now = json.open_now;
				INITIAL_DATA.open_at = json.open_at;
				INITIAL_DATA.close_at = json.close_at;
				INITIAL_DATA.menus = json.menus;
				INITIAL_DATA.categories = json.categories;
				INITIAL_DATA.products = json.products;

				setLoading(false);
                
            })
            .catch((error) => {
            })
            .finally(()=>{
            });

        return ()=>{}

    }, []);

    if(loading){
        return <Loader />
    }

    return props.children;
}

function Root(props){
	const [searchParams] = useSearchParams();
	const location = useLocation();

	useEffect(() => {
		if(!location.pathname.startsWith("/menu/")){
			if(location.hash === ""){
				window.scrollTo({ top: 0, behavior:"instant" });
			}
		}
		
        return () => {};
    }, [location]);

	let lang = searchParams.get("lang");
	if(lang){
		//we have a lang in our link now we check if its valid
		if(Config.LANGUAGES[lang]){
			localStorage.lang = lang;
		}
	}
	
	return <Outlet />
}

