module.exports = {
    WEBSITE_NAME: "Surorile",
    // WEBSITE_TEL: "+40 756 731 722",
    // WEBSITE_EMAIL: "info@surorile.ro",
    WAZE: "https://waze.com/ul/hsxft17ztq",

    GLOVO: "https://glovoapp.com/ro/en/bucharest/surorile-buc/",
    BOLT: "https://food.bolt.eu/en-US/325/p/81857-surorile",
    TAZZ: "https://tazz.ro/bucuresti/surorile/20676/restaurant",
    DISH: "https://restaurant-surorile.order.app.hd.digital/menus",

    INSTAGRAM: "https://www.instagram.com/Surorile.ro/",
    FACEBOOK: "https://www.facebook.com/Surorile.ro",
    TIKTOK: "https://www.tiktok.com/@Surorile.ro",
    YOUTUBE: "https://www.youtube.com/@Surorile.ro",

    LANGUAGES: {
        "ro": {
            code: "ro",
            name: "Română"
        },

        "en": {
            code: "en",
            name: "English"
        },

        "ru": {
            code: "ru",
            name: "Русский"
        }
    }
}