module.exports = {
    request,
    timeAgo,
}

function request(url, options = {}, timeout = 10000) {

    if(!options.credentials){
        options.credentials = 'include';
    }

    return new Promise((resolve, reject) => {
        // Set timeout timer
        let timer = setTimeout(
            () => reject(new Error('Request timed out')),
            timeout
        );

        fetch(url, options).then(
            response => resolve(response),
            err => reject(err)
        ).finally(() => clearTimeout(timer));
    })

}

function timeAgo(date) {
    const seconds = Math.floor((new Date() - date) / 1000);
  
    let interval = Math.floor(seconds / 31536000);
    if (interval === 1) {
        return '1 year ago';
    }
    if (interval > 1) {
        return interval + ' years ago';
    }
  
    interval = Math.floor(seconds / 2592000);
    if (interval === 1) {
        return '1 month ago';
    }
    if (interval > 1) {
        return interval + ' months ago';
    }
  
    interval = Math.floor(seconds / 86400);
    if (interval === 1) {
        return '1 day ago';
    }
    if (interval > 1) {
        return interval + ' days ago';
    }
  
    interval = Math.floor(seconds / 3600);
    if (interval === 1) {
        return '1 hour ago';
    }
    if (interval > 1) {
        return interval + ' hours ago';
    }
  
    interval = Math.floor(seconds / 60);
    if (interval === 1) {
        return '1 minute ago';
    }
    if (interval > 1) {
        return interval + ' minutes ago';
    }
  
    if(seconds < 10) return 'just now';
  
    return Math.floor(seconds) + ' seconds ago';

};