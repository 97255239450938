import { createSlice } from '@reduxjs/toolkit'

function getProducts(){
    try {
        let products = JSON.parse(localStorage.cart);
        if(!Array.isArray(products)){
            throw new Error("Cart is not valid");
        }

        return products;
    } catch (error) {
        // console.error(error);
    }

    return [];
}

function setProducts(products){
    localStorage.cart = JSON.stringify(products);
}

export const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        products: getProducts(),
    },
    reducers: {
        addProductWithQuantity: (state, action) => {

            let found = false;
            for(let i = 0; i < state.products.length; i++){
                let item = state.products[i];

                if(item.product_id == action.payload.product_id){
                    item.in_cart += action.payload.quantity;
                    found = true;
                    break;
                }
            }

            if(!found){
                state.products.push({
                    product_id: action.payload.product_id,
                    in_cart: action.payload.quantity,
                });
            }

            setProducts(state.products);
            
        },

        addProduct: (state, action) => {

            let found = false;
            for(let i = 0; i < state.products.length; i++){
                let item = state.products[i];

                if(item.product_id == action.payload){
                    item.in_cart++;
                    found = true;
                    break;
                }
            }

            if(!found){
                state.products.push({
                    product_id: action.payload,
                    in_cart: 1,
                });
            }

            setProducts(state.products);
            
        },

        subProduct: (state, action) => {

            for(let i = 0; i < state.products.length; i++){
                let item = state.products[i];

                if(item.product_id == action.payload){
                    if(item.in_cart > 1){
                        item.in_cart--;
                    }else{
                        state.products.splice(i, 1);
                    }
                    break;
                }
            }

            setProducts(state.products);
            
        },

        clearAll: (state, action) => {

            state.products = [];
            setProducts(state.products);
            
        },
    },
})

export const { addProductWithQuantity, addProduct, subProduct, clearAll } = cartSlice.actions
export default cartSlice.reducer